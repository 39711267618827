<template>
  <table class="table table-bordered">
    <thead class="thead-light">
    <tr>
      <th class="text-left">
        {{ $t("studentGroups.list.name") }}
      </th>
      <th class="text-left">
        {{ $t("studentGroups.list.level") }}
      </th>
      <th class="text-left">
        {{ $t("studentGroups.list.year") }}
      </th>
      <th class="text-left">
        {{ $t("studentGroups.list.shift") }}
      </th>
      <th class="text-left"></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in groups" :key="item.id">
      <td>{{ item.groupName }}</td>
      <td>{{ item.level }}</td>
      <td>{{ item.educationYearString }}</td>
      <td>{{ shiftVal(item.shift) }}</td>
      <td>
        <router-link :to="{ name: 'teacherInfo', params: { id: item.id }}" class="btn btn-icon btn-success">
          <i class="flaticon2-arrow"></i>
        </router-link>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<style>

</style>


<script>
import ApiService from "@/common/api.service";

export default {
  name: "TeacherClassRooms",
  components: {    
  },
  props:{
    teacherId:String
  },
  data() {
    return {
      groups:[]
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData(){
      let $this = this;
      ApiService.querySecured("studentGroups/classroomForTeacher", {
        params: {teacherId: this.teacherId}
      })
          .then(({data}) => {
            $this.groups = data;
          })
          .catch(({response}) => {
            console.log(response);
          });
    },

    shiftVal(shift) {
      let shiftVal = '';
      switch (shift) {
        case 1:
          shiftVal = 'Первая';
          break;
        case 2:
          shiftVal = 'Вторая';
          break;
        case 3:
          shiftVal = 'Третья';
          break;
      }

      return shiftVal;
    }
  }
};
</script>
