<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("schoolTimeTable.title") }}
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th class="text-left">
                  {{ $t("schoolTimeTable.list.name") }}
                </th>
                <th class="text-left">
                  {{ $t("studentGroups.list.shift") }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ timeTable.name }}</td>
                <td>{{ dictionariesService.getSiftVal(timeTable.shift) }}</td>
                <td>
                </td>
              </tr>
              </tbody>
            </table>
            <h1>Уроки без указания кабинета</h1>
              <table class="table table-bordered">
                <thead class="thead-light">
                <tr>
                  <th class="text-left">
                    Класс
                  </th>
                  <th class="text-left">
                    Предмет
                  </th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, k) in subjectsWithWithoutRooms" :key="k">
                  <td>{{ item.groupName }}</td>
                  <td>{{ item.subjectName }}</td>
                  <td>
                    <button class="btn btn-primary" @click="showGroupSubjectsModal(item.groupId, item.groupName)">
                      <i class="fa fa-pen"></i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            <div>
              <b-button type="button"
                        variant="primary"
                        @click="generate"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
                        :disabled="loading"
                        v-if="showGenerateButton()"
              >Сгенерировать расписание
              </b-button>
              <b-button type="button"
                        variant="primary"
                        @click="showApplyForm"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
                        :disabled="loading"
                        style="margin-left: 10px"
              >
                {{ $t("schoolTimeTable.applyButton") }}
              </b-button> 
              
              <b-button type="button"
                        variant="primary"
                        @click="clearTimeTable"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
                        :disabled="loading"
                        style="margin-left: 10px"
              >
                {{ $t("schoolTimeTable.clearButton") }}
              </b-button>
            </div>


            <b-tabs pills card>
              <b-tab>
                <template v-slot:title>
                  По классам
                </template>
                <div v-for="group in groupsData" :key="group.groupId" :reDrawTable="reDrawTable">
                  <div class="group-name">
                    {{ group.groupName }}
                    
                    <button class="btn btn-primary" @click="showByRoomsModal">
                      По кабинетам
                    </button>   
                    <button class="btn btn-primary" @click="showByTeachersModal">
                      По преподавателям
                    </button>     
                    <button class="btn btn-primary" @click="showGroupSubjectsModal(group.groupId, group.groupName)">
                      <i class="fa fa-pen"></i> Предметы
                    </button>
                  </div>                  
                  <div class="row">
                    <div class="col-lg-3">
                      <div style="overflow-x: auto; max-height: 500px">
                        <div v-for="(undistributedLesson, k) in getSubjectsPerWeekByGroup(group.groupId)" :key="k"
                             class="undistributed-lessons rounded-lg"
                             :style="{'background-color': undistributedLesson.color}">

                          {{ undistributedLesson.text }} {{ undistributedLesson.teacherName }} {{ undistributedLesson.roomNumber }}
                          <div>
                            <button class="btn btn-icon btn-danger"
                                    @click="createByMoveItemModal(undistributedLesson, group.groupId)"
                                    v-if="editMode">
                              <i class="fa fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-9">
                      <table class="table table-bordered">
                        <thead class="thead-light">
                        <tr>
                          <th class="text-left">#</th>
                          <th class="text-left" v-for="day in days" :key="day">
                            {{ $t("schoolTimeTable.day." + day) }}
                          </th>
                        </tr>
                        </thead>
                        <tbody :reDrawTable="reDrawTable">
                        <tr v-for="dayItem in dayItems" :key="dayItem">
                          <td>{{ dayItem }}</td>
                          <td v-for="day in days" :key="day">
                            <div v-for="(item1, k) in group['days'][day][dayItem]" :key="k"
                                 class="time-table-item rounded-lg">
                              {{ item1.lessonName }}, {{ item1.lessonTeacher }}, {{ item1.roomNumber }}
                              <br>
                              <button @click.self="removeItem(item1)"
                                      class="btn btn-danger">Удалить
                              </button>

                              <button @click.self="editItem(item1)"
                                      class="btn btn-danger" style="margin-left: 10px">Переместить
                              </button>

                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  По кабинетам
                </template>
                <div v-for="classroom in classroomsData" :key="classroom.id" :reDrawTable="reDrawTable">
                  <div class="group-name">
                    {{ classroom.roomNumber }}
                  </div>
                  <div class="col-lg-12">
                  </div>
                  <table class="table table-bordered">
                    <thead class="thead-light">
                    <tr>
                      <th class="text-left">#</th>
                      <th class="text-left" v-for="day in days" :key="day">
                        {{ $t("schoolTimeTable.day." + day) }}
                      </th>
                    </tr>
                    </thead>
                    <tbody :reDrawTable="reDrawTable">
                    <tr v-for="dayItem in dayItems" :key="dayItem">
                      <td>{{ dayItem }}</td>
                      <td v-for="day in days" :key="day">

                        <div v-for="(classroomData, k) in classroom['days'][day][dayItem]" :key="k"
                             class="time-table-item rounded-lg">
                          {{ classroomData.lessonName }}, {{ classroomData.lessonTeacher }}, {{ classroomData.groupName }}
                          <br>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  По преподавателям
                </template>
                <div v-for="teacher in teachersData" :key="teacher.id" :reDrawTable="reDrawTable">
                  <div class="group-name">
                    {{ teacher.teacherName }}
                  </div>
                  <div class="col-lg-12">
                  </div>
                  <table class="table table-bordered">
                    <thead class="thead-light">
                    <tr>
                      <th class="text-left">#</th>
                      <th class="text-left" v-for="day in days" :key="day">
                        {{ $t("schoolTimeTable.day." + day) }}
                      </th>
                    </tr>
                    </thead>
                    <tbody :reDrawTable="reDrawTable">
                    <tr v-for="dayItem in dayItems" :key="dayItem">
                      <td>{{ dayItem }}</td>
                      <td v-for="day in days" :key="day">

                        <div v-for="(teacherData, k) in teacher['days'][day][dayItem]" :key="k"
                             class="time-table-item rounded-lg">
                          {{ teacherData.lessonName }}, {{ teacherData.roomNumber }}, {{ teacherData.groupName }}
                          <br>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </b-tab>
            </b-tabs>

            

          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">

    </div>
    <b-modal id="moveItem" ref="schoolTT-moveItem-modal" @hide="forceReDraw">
      <template v-slot:modal-title>
        {{ $t("common.move") }}
      </template>
      <div style="height: 400px; overflow-y: auto">
        <div>
          {{ moveItem.subjectName }}
        </div>
        <div>
          Учитель - {{ moveItem.teacherName }}
        </div>
        <div>
          Кабинет - {{ moveItem.roomNumber }}
        </div>
        <b-form>
          <b-form-group>
            <template v-slot:label>
              {{ $t("timeTable.moveDay") }}
            </template>
            <b-form-select
                v-model="moveItem.day"
                :options="dayOptions"
            ></b-form-select>
          </b-form-group>
          <b-form-group>
            <template v-slot:label>
              {{ $t("timeTable.moveDayItem") }}
            </template>
            <b-form-select
                v-model="moveItem.lessonNumber"
                :options="dayItemOptions"
            ></b-form-select>
          </b-form-group>
        </b-form>
      </div>

      <template v-slot:modal-footer>
        <b-button type="reset" @click="moveButtonPressed" variant="success"
        >{{
            $t("common.move")
          }}
        </b-button>
        <b-button type="reset" @click="closeMoveModal" variant="danger"
        >{{
            $t("common.close")
          }}
        </b-button>
      </template>
    </b-modal>

    <b-modal id="schoolTTApplyModal" ref="schoolTTApplyModal">
      <template v-slot:modal-title>
        {{ $t("schoolTimeTable.applyForm.title") }}
      </template>
      <div style="height: 400px; overflow-y: auto">
        <b-form>
          <b-form-group>
            <template v-slot:label>
              {{ $t("schoolTimeTable.applyForm.date") }}
            </template>
            <b-form-datepicker
                v-model="applyForm.date"
                :date-disabled-fn="dateDisabled"
                start-weekday="1"
                locale="ru"
            ></b-form-datepicker>
          </b-form-group>
        </b-form>
      </div>

      <template v-slot:modal-footer>
        <b-button type="reset" @click="applyTimeTable" variant="success"
        >{{
            $t("schoolTimeTable.applyButton")
          }}
        </b-button>
        <b-button type="reset" @click="hideApplyForm" variant="danger"
        >{{
            $t("common.close")
          }}
        </b-button>
      </template>
    </b-modal>

    <b-modal id="schoolTTByRoomsModal" ref="schoolTTByRoomsModal" size="xl">
      <template v-slot:modal-title>
        По кабинетам

        <b-form-select
            v-model="selectedClassroomId"
            :options="classroomNumbers"
        ></b-form-select>
      </template>
      <div style="max-height: 700px; overflow-y: auto">
        <div v-for="classroom in classroomsDataByRoom" :key="classroom.id">
          <div v-if="selectedClassroomId == 0 || selectedClassroomId == classroom.id">
            <div class="group-name">
              {{ classroom.roomNumber }}
            </div>
            <div class="col-lg-12">
            </div>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th class="text-left">#</th>
                <th class="text-left" v-for="day in days" :key="day">
                  {{ $t("schoolTimeTable.day." + day) }}
                </th>
              </tr>
              </thead>
              <tbody :reDrawTable="reDrawTable">
              <tr v-for="dayItem in dayItems" :key="dayItem">
                <td>{{ dayItem }}</td>
                <td v-for="day in days" :key="day">

                  <div v-for="(classroomData, k) in classroom['days'][day][dayItem]" :key="k"
                       class="time-table-item rounded-lg">
                    {{ classroomData.lessonName }}, {{ classroomData.lessonTeacher }}, {{ classroomData.groupName }}
                    <br>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>          
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button type="reset" @click="hideByRoomsModal" variant="danger"
        >{{
            $t("common.close")
          }}
        </b-button>
      </template>
    </b-modal>
    <b-modal id="schoolTTByTeachersModal" ref="schoolTTByTeachersModal" size="xl">
      <template v-slot:modal-title>
        По преподавателям
        <b-form-select
            v-model="selectedTeacherId"
            :options="teacherNames"
        ></b-form-select>
      </template>
      <div style="max-height: 700px; overflow-y: auto">
        <div v-for="teacher in classroomsDataByTeacher" :key="teacher.id">
          <div v-if="selectedTeacherId == 0 || selectedTeacherId == teacher.id">
            <div class="group-name">
              {{ teacher.teacherName }}
            </div>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th class="text-left">#</th>
                <th class="text-left" v-for="day in days" :key="day">
                  {{ $t("schoolTimeTable.day." + day) }}
                </th>
              </tr>
              </thead>
              <tbody :reDrawTable="reDrawTable">
              <tr v-for="dayItem in dayItems" :key="dayItem">
                <td>{{ dayItem }}</td>
                <td v-for="day in days" :key="day">

                  <div v-for="(teacherData, k) in teacher['days'][day][dayItem]" :key="k"
                       class="time-table-item rounded-lg">
                    {{ teacherData.lessonName }}, {{ teacherData.roomNumber }}, {{ teacherData.groupName }}
                    <br>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>          
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button type="reset" @click="hideByTeachersModal" variant="danger"
        >{{
            $t("common.close")
          }}
        </b-button>
      </template>
    </b-modal>
    
    <b-modal id="schoolTTGroupSubjectsModal" ref="schoolTTGroupSubjectsModal" size="xl">
      <template v-slot:modal-title>
        Предметы - {{groupName}}
      </template>
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th class="text-left">
            {{ $t("groupSubjects.list.subject") }}
          </th>
          <th class="text-left">
            {{ $t("groupSubjects.list.teacher") }}
          </th>   
          <th class="text-left">
            {{ $t("groupSubjects.list.classWorkPerWeekHours") }}
          </th>
          <th class="text-left">
            {{ $t("groupSubjects.list.roomNumber") }}
          </th>
          <th class="text-left">
            {{ $t("groupSubjects.list.roomNumberAdditional") }}
          </th>
          <th class="text-left">
            {{ $t("groupSubjects.list.roomNumberAdditional2") }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, k) in groupSubjects" :key="k">
          <td>{{ item.subjectName }}</td>
          <td>{{ item.teacherName }}</td>
          <td>{{ item.perWeekCount }}</td>
          <td>
            <b-form-select
                v-model="item.classroomId"
                :options="classrooms"
                @change="changeSubjectData(item)"
            ></b-form-select>
          </td>
          <td>
            <b-form-select
                v-model="item.classroomAdditionalId"
                :options="classrooms"
                @change="changeSubjectData(item)"
            ></b-form-select>
          </td>
          <td>
            <b-form-select
                v-model="item.classroomAdditionalId2"
                :options="classrooms"
                @change="changeSubjectData(item)"
            ></b-form-select>
          </td>
        </tr>
        </tbody>
      </table>

      <template v-slot:modal-footer>
        <b-button type="reset" @click="hideGroupSubjectsModal" variant="danger"
        >{{
            $t("common.close")
          }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<style>

button.btn-primary{
  color: white !important;
}

.group-name {
  line-height: 35px;
  background: #84D49E;
  color: #FFFFFF;
  font-weight: bold;
  padding: 10px 15px;
  margin: 15px 0 0 0;
  position: relative;
  min-height: 45px;
}

.group-name>button{
  margin-left: 10px;
}

.time-table-item {
  width: 94%;
  background: #1BC5BD;
  color: #FFFFFF;
  margin: 4px;
  padding: 5px 5px;
  cursor: pointer;
}

.undistributed-lessons {
  background: #1BC522;
  width: 200px;
  float: left;
  margin: 5px 10px 0 0;
  padding: 10px;
  color: #FFFFFF;
}

.undistributed-lessons > div {
  color: white;
}

.undistributed-lessons > div > button {
  width: 100%;
}
</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "SchoolTimeTable",
  components: {
    KTPortlet
  },
  computed: {
    dictionariesService() {
      return DictionariesService
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      list: null,
      timeTable: {
        name: "",
        shift: "",
      },
      shifts: DictionariesService.shiftOptions(),
      groupsData: [],
      classroomsData: {},
      teachersData: {},
      dayItems: DictionariesService.dayItemsShort,
      days: DictionariesService.days,
      reDrawTable: 0,
      usedItems: {},
      subjectsPerWeekByGroup: {},
      editMode: true,
      moveItem: {
        id: 0,
        schoolTimeTableId: 0,
        day: 0,
        lessonNumber: 0,
        subjectIdUniversal: 0,
        groupSubjectId: '',
        groupSubjectSubGroupId: '',

        roomNumber: '',
        teacherName: '',
        subjectName: '',
      },

      dayOptions: DictionariesService.getDayOptions(),
      dayItemOptions: DictionariesService.getDayItemOptionsShort(),
      loading: false,
      applyForm: {
        date: new Date(),
        id: this.$route.params.id,
      },
      subjectsWithWithoutRooms: [],
      classroomsDataByRoom:{},
      classroomsDataByTeacher:{},
      groupSubjects:[],
      classrooms: [],
      groupName: "",
      
      teacherNames: [],
      classroomNumbers: [],
      
      selectedTeacherId: 0,
      selectedClassroomId: 0,
    };
  },
  mounted() {
    let $this = this;
    ApiService.querySecured("dictionaries/getClassrooms", {}).then(({data}) => {
      $this.classrooms = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
    
    this.reloadData();
  },
  methods: {
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday === 0;
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};
    },
    reloadData() {
      let $this = this;
      $this.loading = true;
      ApiService.querySecured("schoolTimeTable/find", {params: {id: this.id}})
          .then(function ({data}) {
            $this.timeTable = data;
          })
          .catch(({response}) => {
            console.log(response);
          });      
      
      ApiService.querySecured("schoolTimeTable/getClassroomsReport", {params: {id: this.id}})
          .then(function ({data}) {
            $this.subjectsWithWithoutRooms = data.classrooms;
          })
          .catch(({response}) => {
            console.log(response);
          });


      ApiService.querySecured("schoolTimeTable/details", {params: {id: this.id}})
          .then(function ({data}) {
            $this.groupsData = [];
            $this.classroomsData = {};
            $this.teachersData = {};

            $this.classroomNumbers.push({
              value: 0,
              text: ""
            });    
            
            $this.teacherNames.push({
              value: 0,
              text: ""
            });
            
            for (let classroomIndex in data.classrooms){
              let classroom = data.classrooms[classroomIndex];
              $this.classroomNumbers.push({
                value: classroom.id,
                text: classroom.roomNumber
              });
              $this.classroomsData[classroom.id] = {
                id:classroom.id,
                roomNumber: classroom.roomNumber,
                days: {
                  1: {},
                  2: {},
                  3: {},
                  4: {},
                  5: {},
                  6: {}
                }
              };

              for (let ddIndex in $this.classroomsData[classroom.id]['days']) {
                for (let ii in $this.dayItems) {
                  let iiVal = $this.dayItems[ii];

                  $this.classroomsData[classroom.id]['days'][ddIndex][iiVal] = [];
                }
              }
            }  
            
            for (let teacherIndex in data.teachers){
              let teacher = data.teachers[teacherIndex];

              $this.teacherNames.push({
                value: teacher.id,
                text: teacher.teacherName
              });
              
              $this.teachersData[teacher.id] = {
                id:teacher.id,
                teacherName: teacher.teacherName,
                days: {
                  1: {},
                  2: {},
                  3: {},
                  4: {},
                  5: {},
                  6: {}
                }
              };

              for (let ddIndex in $this.teachersData[teacher.id]['days']) {
                for (let ii in $this.dayItems) {
                  let iiVal = $this.dayItems[ii];

                  $this.teachersData[teacher.id]['days'][ddIndex][iiVal] = [];
                }
              }
            }            

            for (let gIndex in data.groups) {
              let group = data.groups[gIndex];
              // used
              $this.usedItems[group.groupId] = {};
              $this.subjectsPerWeekByGroup[group.groupId] = group.subjectsPerWeek;
              for (let subjectsPerWeekIndex in group.subjectsPerWeek) {
                let ss = group.subjectsPerWeek[subjectsPerWeekIndex]
                $this.usedItems[group.groupId][ss.value] = 0;
              }

              //
              let ggData = {
                groupId: group.groupId,
                groupName: group.groupName,
                days: {
                  1: {},
                  2: {},
                  3: {},
                  4: {},
                  5: {},
                  6: {}
                }
              };

              for (let ddIndex in ggData['days']) {
                for (let ii in $this.dayItems) {
                  let iiVal = $this.dayItems[ii];

                  ggData['days'][ddIndex][iiVal] = [];
                }
              }

              for (let dIndex in group['timeTableForWeek']['days']) {
                let day = group['timeTableForWeek']['days'][dIndex];
                for (let sIndex in day['subjects']) {
                  let subject = day['subjects'][sIndex];

                  $this.classroomsData[subject.classroomId]['days'][day.day][subject.lessonNumber].push({
                    lessonName: subject.lessonName,
                    lessonTeacher: subject.lessonTeacher,
                    groupName: group.groupName,
                  });

                  $this.teachersData[subject.teacherId]['days'][day.day][subject.lessonNumber].push({
                    lessonName: subject.lessonName,
                    roomNumber: subject.roomNumber,
                    groupName: group.groupName,
                  });

                  console.log('asaas');
                  
                  $this.usedItems[group.groupId][subject.subjectIdUniversal] =
                      $this.usedItems[group.groupId][subject.subjectIdUniversal] + 1;
                  
                  ggData['days'][day.day][subject.lessonNumber].push(subject);
                }
              }

              $this.groupsData.push(ggData);

              $this.reDrawTable = Math.random();
            }

            $this.loading = false;
          })
          .catch(({response}) => {
            console.log(response);
            $this.loading = false;
          });
    },
    cleanModel() {
      this.form = {};
    },
    generate() {
      let $this = this;
      $this.loading = true;
      ApiService.postSecured("schoolTimeTable/createSchedule", {id: parseInt(this.id)})
          .then(function () {

            $this.reloadData();
            $this.cleanModel();
            $this.reDrawTable = Math.random();

            $this.loading = false;
          })
          .catch(({response}) => {
            console.log(response);
            $this.reloadData();

            $this.reDrawTable = Math.random();

            $this.loading = false;
          });
    },
    removeItem(item) {
      console.log(item);
      if (!confirm('Удалить урок?')) {
        return;
      }

      if (!isNaN(item.id) && item.id > 0) {

        let data = {id: item.id};
        let $this = this;
        $this.loading = true;
        ApiService.postSecured("schoolTimeTable/deleteItem", data)
            .then(function () {
              //alert("Данные сохранены");

              $this.reloadData();

              $this.loading = false;
            })
            .catch(({response}) => {
              console.log(response);

              $this.loading = false;
            });
      }

    },
    editItem(item) {
      this.moveItem = {
        id: item.id,
        schoolTimeTableId: this.id,
        day: item.day,
        lessonNumber: item.lessonNumber,
        subjectIdUniversal: item.subjectIdUniversal,
        groupSubjectId: item.groupSubjectId,
        groupSubjectSubGroupId: item.groupSubjectSubGroupId,

        roomNumber: item.roomNumber,
        teacherName: item.lessonTeacher,
        subjectName: item.lessonName,
      };

      this.$refs["schoolTT-moveItem-modal"].show();
    },
    saveItem(data) {
      let $this = this;
      $this.loading = true;
      ApiService.postSecured("schoolTimeTable/saveItem", data)
          .then(function () {
            //alert("Данные сохранены");
            $this.loading = false;

            $this.reloadData();
          })
          .catch(({response}) => {
            console.log(response);

            $this.loading = false;
          });
    },
    moveButtonPressed() {
      this.$refs["schoolTT-moveItem-modal"].hide();

      this.saveItem(this.moveItem);

      this.moveItem = {};
    },
    closeMoveModal() {

      this.$refs["schoolTT-moveItem-modal"].hide();
      this.moveItem = {};
    },
    createByMoveItemModal(undistributedLesson) {
      this.moveItem = {
        id: 0,
        schoolTimeTableId: parseInt(this.id),
        day: 1,
        lessonNumber: 1,
        subjectIdUniversal: undistributedLesson.value,
        groupSubjectId: undistributedLesson.subjectId,
        groupSubjectSubGroupId: undistributedLesson.subgroupId == null ? 0 : undistributedLesson.subgroupId,

        roomNumber: undistributedLesson.roomNumber,
        teacherName: undistributedLesson.lessonTeacher,
        subjectName: undistributedLesson.lessonName,
      };

      this.$refs["schoolTT-moveItem-modal"].show();
    },
    getSubjectsPerWeekByGroup(groupId) {
      let ret = [];
      for (let i in this.subjectsPerWeekByGroup[groupId]) {
        let item = this.subjectsPerWeekByGroup[groupId][i];

        let cc = item.perWeekCount - this.usedItems[groupId][item.value];
        if (cc > 0) {
          for (let i = 0; i < cc; i++) {
            ret.push(item)
          }
        }
      }

      return ret;
    },
    forceReDraw() {
      this.reDrawTable = Math.random();
    },
    showGenerateButton() {
      if (this.groupsData.length > 0) {
        return true
      }

      return false;
    },
    showApplyForm() {
      this.$refs["schoolTTApplyModal"].show();
    },
    applyTimeTable() {
      this.$refs["schoolTTApplyModal"].hide();
      let $this = this;
      $this.loading = true;
      let data = {
        date: this.applyForm.date,
        timeTableId: parseInt(this.id)
      }
      ApiService.postSecured("schoolTimeTable/applyTimeTable", data)
          .then(function () {
            $this.loading = false;

            $this.reloadData();
          })
          .catch(({response}) => {
            console.log(response);

            $this.loading = false;
          });
    },
    clearTimeTable() {
      if(!confirm("Будут удалены все данные")){
        return;
      }
      
      let $this = this;
      $this.loading = true;
      ApiService.postSecured("schoolTimeTable/clear", {id: parseInt(this.id)})
          .then(function () {
            $this.loading = false;

            $this.reloadData();
          })
          .catch(({response}) => {
            console.log(response);

            $this.loading = false;
          });
    },
    hideApplyForm() {
      this.$refs["schoolTTApplyModal"].hide();
    },   
    showByRoomsModal() {
      this.$refs["schoolTTByRoomsModal"].show();

      this.classroomsDataByRoom = this.classroomsData;
    },
    hideByRoomsModal() {
      this.$refs["schoolTTByRoomsModal"].hide();
      this.classroomsDataByRoom = [];
    },
    showByTeachersModal() {
      this.$refs["schoolTTByTeachersModal"].show();

      this.classroomsDataByTeacher = this.teachersData;
    },
    hideByTeachersModal() {
      this.$refs["schoolTTByTeachersModal"].hide();
      this.classroomsDataByTeacher = [];
    },
    showGroupSubjectsModal(groupId, groupName) {
      this.groupName = groupName;
      let $this = this;
      
      ApiService.querySecured("groupSubjects/getForGroupForSchoolTimeTable", {params: {groupId: groupId}}).then(({data}) => {
        this.$refs["schoolTTGroupSubjectsModal"].show();
        
        $this.groupSubjects = data;
        
      }).catch(({response}) => {
        console.log(response);
      });
    },
    hideGroupSubjectsModal() {
      this.$refs["schoolTTGroupSubjectsModal"].hide();
      this.classroomsDataByTeacher = [];
    },
    changeSubjectData(data) {
      let $this = this
      ApiService.postSecured("groupSubjects/saveGroupFromSchoolTimeTable", data)
          .then(function () {

            $this.reloadData();

            $this.loading = false;
          })
          .catch(({response}) => {
            console.log(response);

            $this.loading = false;
          });
    },
  }
};
</script>
<script setup>
import TeacherClassRooms from "../schools/teacher/partials/TeacherClassRooms.vue";
</script>